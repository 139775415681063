<template>
  <div class="row vh-100 m-0 mt-lg-5 me-lg-5">
    <div
      class="d-flex align-items-center justify-content-center justify-content-lg-end"
    >
      <form class="login-form" @submit.prevent="auth">
        <h6 class="fw-light text-center text-white mb-3">
          Voce acessará como credenciamento
        </h6>
        <div class="card mb-0">
          <div class="card-body">
            <div class="text-center mb-3">
              <div
                class="d-inline-flex align-items-center justify-content-center my-2 d-lg-none"
              >
                <img src="@/assets/img/logos/icone-02.svg" class="h-48px" />
              </div>
              <h4 class="text-secondary mb-0">Conecte ao sistema</h4>
            </div>
            <div class="mb-3">
              <label class="form-label">Usuário</label>
              <div class="form-control-feedback form-control-feedback-start">
                <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': v$.user.$errors.length }"
                  placeholder="Usuário"
                  v-model="v$.user.$model"
                />
                <div class="form-control-feedback-icon">
                  <i class="ph-user-circle text-muted"></i>
                </div>
                <span v-if="v$.user.required.$invalid" class="invalid-feedback">
                  O campo é obrigatório
                </span>
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label">Senha</label>
              <div class="form-control-feedback form-control-feedback-start">
                <input
                  type="password"
                  class="form-control"
                  :class="{ 'is-invalid': v$.password.$errors.length }"
                  placeholder="•••••••••••"
                  v-model="v$.password.$model"
                />
                <div class="form-control-feedback-icon">
                  <i class="ph-lock text-muted"></i>
                </div>
                <span
                  v-if="v$.password.required.$invalid"
                  class="invalid-feedback"
                >
                  O campo é obrigatório
                </span>
                <span
                  v-if="v$.password.minLength.$invalid"
                  class="invalid-feedback"
                >
                  A senha deve ter pelo menos
                  {{ v$.password.minLength.$params.min }} caracteres
                </span>
              </div>
            </div>
            <div class="mb-3">
              <button
                type="submit"
                class="btn btn-secondary w-100"
                title="Login"
                :disabled="v$.$invalid || disableButton"
              >
                <span v-if="disableButton">
                  <i class="ph-spinner spinner" />
                </span>
                <span v-else>Login</span>
              </button>
            </div>
            <div class="text-center" title="Alterar perfil de acesso">
              <router-link to="/">Alterar perfil de acesso</router-link>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import PATHS from '@/router/routesMap'

export default {
  name: 'AccreditationAuth',

  setup() {
    return { v$: useVuelidate() }
  },

  data() {
    return {
      backgroundImg: 'background-11.jpg',
      user: '',
      password: '',
      disableButton: false,
    }
  },

  methods: {
    async auth() {
      if (!this.v$.$invalid) {
        this.disableButton = true
        try {
          await this.$store.dispatch('authAccreditation', {
            user: this.user,
            password: this.password,
          })
          this.disableButton = false
          this.$router.push(PATHS.ACCREDITATION_HOME)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$refs.notification.warning(error.message)
          } else {
            this.$root.$refs.notification.error(
              'Não foi possível se conectar ao servidor.',
            )
          }
        }
      }
    },
  },

  validations() {
    return {
      user: {
        required,
      },
      password: {
        required,
        minLength: minLength(6),
      },
    }
  },

  created() {
    this.$store.dispatch('setBackground', this.backgroundImg)
  },
}
</script>

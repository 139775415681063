<template>
  <div class="row vh-100 m-0">
    <div
      class="col-lg-6 d-flex flex-column align-items-center justify-content-center p-0"
    >
      <div class="d-flex flex-column">
        <h4 class="text-secondary">O que você deseja fazer?</h4>
        <router-link
          class="btn btn-secondary btn-lg mb-3"
          to="schedules/list"
          title="Visualizar Agendamentos"
        >
          <i class="ph ph-calendar-plus me-2"></i>
          Lista de Agendamentos
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',

  mounted() {
    this.$router.push({
      name: 'AccreditationSchedulesList',
    })
  },
}
</script>
